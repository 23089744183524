import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'
import { IKService } from "../services/ik_service";

var ValidateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const EmailError = () => {
  return (
    <div className="warn">Invalid email address!</div>
  );
}
const NameError = () => {
  return (
    <div className="warn">Invalid name!</div>
  );
}
const QueryError = () => {
  return (
    <div className="warn">Invalid query or too short!</div>
  );
}


class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { name: '', email: '', query: '', mode: 'contact' };
  }

  componentDidMount () { }

  handleNameInput = event => {
    this.setState({ name: event.target.value, error:'' });
  };
  handleEmailInput = event => {
    this.setState({ email: event.target.value, error:'' });
  };
  handleQueryInput = event => {
    console.log(event);
    this.setState({ query: event.target.value, error:'' });
  };

  renderForm() {
    if(this.state.mode !== 'contact') { return; }
    return(
      <div>
        <form className="form form-wide-input" method="post" id="Form">
          <p>
            Contact me directly on this form and I'll get back to you as soon as possible. By submitting your details you are agreeing to our <a href="/privacy">privacy policy</a>
          </p>
          <p>
            <input name="Name" id="fullName" type="text" onChange={this.handleNameInput} placeholder="Jon / Jane Doe" />
          </p>
          <p>
            <input name="Email" id="email" type="email" onChange={this.handleEmailInput} placeholder="Email Address" />
          </p>
          <p>
            <textarea name="Query" id="query" placeholder="Enquiry" onChange={this.handleQueryInput}></textarea>
          </p>
          <p>
            <span className="btn btn-primary" id="contactBtn" onClick={this.submit.bind(this)} role="button" tabIndex={0}>Submit Query</span>
          </p>
        </form>
      </div>
    );
  }

  renderPosting() {
    if(this.state.mode !== 'posting') { return; }
    return(
      <div>
        <div className="text-center">Posting...</div>
      </div>
    );
  }

  renderFailure() {
    if(this.state.mode !== 'failure') { return; }
    return(
      <div>
        <div className="text-center">Sending...</div>
      </div>
    );
  }
  renderSuccess() {
    if(this.state.mode !== 'success') { return; }
    return(
      <div>
        <div className="text-center">Thanks for contacting me. I will get back to you ASAP!</div>
      </div>
    );
  }

  submit() {

    console.log(this);

    if(!ValidateEmail(this.state.email)) {
      this.setState({error: EmailError()});
      return;
    }
    if(this.state.name.length < 3) {
      this.setState({error: NameError()});
      return;
    }
    if(this.state.query.length < 20) {
      this.setState({error: QueryError()});
      return;
    }
    var args = {
      query: this.state.query,
      email: this.state.email,
      name: this.state.name,
    };
    this.setState({mode: 'posting', error: ''});

    IKService.contact(args).then(
      data=>{ this.setState({mode: 'success', error: ''}); }
    ).catch(
      err=>{ this.setState({mode: 'failure', error: ''}); }
    );
  }

  render() {
    return (
      <Layout>
        <Helmet defer={false}>
          <title>Contact Ian Knowles</title>
          <meta name="description" content="Contact Ian Knowles" />
        </Helmet>
        <section className="content-section dark">
          <div className="container text-center small-container" id="workspace">
            <h1>Contact</h1>
            <div id="Error">{this.state.error}</div>  
            {this.renderForm()}
            {this.renderPosting()}
            {this.renderFailure()}
            {this.renderSuccess()}
          </div>
        </section>
      </Layout>
    )
  }
}

export default ContactPage